<template>
  <div>
    <EntityList
      ref="entitylist"
      :config="config"
      :columns="columns"
      :formatted-columns="formattedColumns"
      :filters="filters"
      :filter-vals.sync="filterVals"
      :custom-query="{ role: 3 }"
      map-view
      single-edit
      authority="Users_Company"
      inline-actions
    />
  </div>
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../companyStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      countryOptions: [],
      animation: 2,
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'companies',
        endpoint: 'companies',
        route: 'companies',
        title: {
          single: this.$i18n.t('Company'),
          plural: this.$i18n.t('Companies'),
        },
      }
    },

    filters() {
      return [
        {
          name: 'country',
          label: this.$t('country'),
          options: this.countryOptions,
          value: null,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
          value: null,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '4',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '3',
          maxDate: new Date(),
        },
      ]
    },

    // entityItems() {
    //   // console.log(this.$refs.entitylist)
    //   // return this.$refs.entitylist
    // },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
  methods: {},
}
</script>

<style lang="scss">
.vue-map-container {
  height: 750px;
}
</style>
